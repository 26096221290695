import {Component} from '@angular/core';
import {IfCoreInputComponent} from "@if/angular-core-components";
import {FormsModule} from "@angular/forms";
import {NgClass} from "@angular/common";

@Component({
  selector: 'if-demo-demo-overwrite',
  standalone: true,
  imports: [
    FormsModule,
    NgClass
  ],
  templateUrl: './demo-overwrite.component.html',
  styles: ``
})
export class DemoOverwriteComponent extends IfCoreInputComponent {

}
