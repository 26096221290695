<div class="relative flex flex-col" #wrapperEl>
    @if (!labelHidden) {
        <label class="text-sm font-medium text-gray-900 dark:text-slate-200" [for]="name" #labels>
            <ng-content></ng-content>
        </label>
    }
    <input [type]="type"
           [value]="value"
           [name]="name"
           [id]="name"
           [(ngModel)]="value"
           (input)="change($event)"
           (blur)="onTouched()"
           [placeholder]="placeholder"
           [ngClass]="{'border-rose-600': hasError}"
           [maxlength]="maxlength > 0 ? maxlength : null"
           class="p-4 rounded bg-white text-base text-gray-700 border-1 border border-gray-300 dark:border-slate-700 placeholder:text-base placeholder:text-gray-400 placeholder:dark:text-slate-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-slate-900 focus:ring-if-orange-600 dark:bg-slate-600 dark:text-slate-100 disabled:cursor-not-allowed disabled:placeholder:text-gray-300 disabled:border-gray-200 disabled:bg-gray-100 disabled:dark:border-slate-500 disabled:dark:bg-slate-600 disabled:dark:hover:border-slate-500"
           #input
    >
    @if (hasError) {
        <span class="text-rose-600 dark:text-rose-500 font-medium">
    {{ errorMessage }}
  </span>
    }
    @if(maxlength > 0) {
        <div class="input-max">{{ value.length }} / {{ maxlength }}</div>
    }
</div>


