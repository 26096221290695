import {
    IfCoreBannerComponent,
    IfCoreButton,
    IfCoreCheckboxComponent,
    IfCoreInputComponent,
    IfCoreNumberComponent,
    IfCoreRadioBoxedComponent,
    IfCoreRadioComponent,
    IfCoreRadioDotsComponent,
    IfCoreSelectComponent,
    IfCoreTextareaComponent,
    IfCoreToggleComponent
} from "@if/angular-core-components";
// } from "../../../../components/projects/if-components/src/lib";
// import {HotkeysplitPipe, HotkeysService} from "../../../../hotkeys/projects/hotkeys/src/lib"
import {HotkeysplitPipe, HotkeysService} from "@if/angular-hotkeys"
import {DemoOverwriteComponent} from "./demo-overwrite/demo-overwrite.component";

export const SharedLibService = [
    HotkeysService
];

export const SharedLibPipe = [
    HotkeysplitPipe
];

export const SharedLib = [
    IfCoreInputComponent,
    IfCoreNumberComponent,
    IfCoreSelectComponent,
    IfCoreCheckboxComponent,
    IfCoreToggleComponent,
    IfCoreTextareaComponent,
    IfCoreRadioComponent,
    IfCoreRadioBoxedComponent,
    IfCoreRadioDotsComponent,
    IfCoreButton,
    IfCoreBannerComponent,
    DemoOverwriteComponent,
]